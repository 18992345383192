import React from 'react';

const catagories = {
  watercolor: 'Original Watercolor on Paper',
  mixedmedia: 'Original Mixedmedia on Paper',
  canvas_linen: 'Original Work on Stretched Canvas/Linen',
  other: 'Other Original',
};

const sizes = {
  'x-large': 'X Large (~22"x30")',
  large: 'Large (~13"x19")',
  medium: 'Medium (~9"x12")',
  small: 'Small (~5"x7")',
};

const ProductMeta = props => {
  const { medium, size, framing, categoryLevel1, ActualMeasurements, CfMFriends } = props.publicData;
  const { desc } = props;
  // console.log(medium);
  const categoryText = catagories[categoryLevel1];
  const sizeText = sizes[size];
  // console.log(sizeText)

  return (
    <div className="product-meta">
      <p>{desc}</p>
      {CfMFriends ? (
              <p className="txt-2">
              This piece was inspired by a Case for Making Friends class taught by {CfMFriends}.
            </p>
            ) : null}
      
      <table className="table">
        <tbody>
          <tr>
            <td>
              <span className="key">CATEGORY</span>
            </td>
            <td>
              <span className="value">{categoryText}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="key">MEDIUM</span>
            </td>
            <td>
              <span className="value">{medium}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="key">Actual size</span>
            </td>
            <td>
              <span className="value">{ActualMeasurements}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="key">FRAMED/UNFRAMED</span>
            </td>
            <td>
              <span className="value">{framing}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProductMeta;
